import React, { useState } from "react";
import { Howler } from "howler";

import "xp.css/dist/XP.css";
import "./App.css";

const Window = (props: { x: number; y: number }) => {
  Howler.autoUnlock = false;

  return (
    <div
      style={{
        left: props.x,
        top: props.y,
      }}
      className="window"
      onClick={() => {
        console.log("clicked");
        var audio = new Audio("/erro.mp3");
        audio.play();
      }}
    >
      <div className="title-bar">
        <div className="title-bar-text">Command Prompt</div>
        <div className="title-bar-controls">
          <button aria-label="Minimize"></button>
          <button aria-label="Maximize"></button>
          <button aria-label="Close"></button>
        </div>
      </div>
      <div className="window-body">
        <pre>
          Mangina&#10094;R&#10095; DOS &#10094;C&#10095; Copyright Ähkyn Sillis
          Corp 2017-2019.
          <br />
          <br />
          C:&#92;WINDOWS&#92;SYSTEM32{`>`}{" "}
          <span className="blink"> INSERT COIN TO PYLLY</span>
        </pre>
      </div>
    </div>
  );
};

function App() {
  const [drag, setDrag] = useState(false);

  const [windows, setWindows] = useState<{ x: number; y: number }[]>([
    { x: 50, y: 100 },
  ]);

  return (
    <div
      style={{ width: "100%", height: "100%", overflow: "hidden" }}
      onMouseDown={() => {
        console.log("down");
        setDrag(true);
      }}
      onMouseUp={(e) => {
        console.log("up");
        setDrag(false);
      }}
      onMouseMove={(el) => {
        if (drag) {
          console.log(el.clientX, el.clientY);
          setWindows(windows.concat([{ x: el.clientX, y: el.clientY }]));
          var audio = new Audio("/erro.mp3");
          audio.play();
        }
      }}
      onTouchMove={(e) => {
        setWindows(
          windows.concat([{ x: e.touches[0].pageX, y: e.touches[0].pageY }])
        );
        var audio = new Audio("/erro.mp3");
        audio.play();
      }}
    >
      {windows.map((window) => {
        return <Window x={window.x} y={window.y} />;
      })}
    </div>
  );
}

export default App;
